@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.oswald {
  font-family: 'Oswald', sans-serif;
}


.btn-primary {
  background: #ff432e;
  border-color: #ffffff;
  font-size: 16px;
  padding: 15px 25px;
  color: #fff;
  transition: all 0.3s;
}

.btn-primary:hover {
  background: transparent;
  border-color: #ff432e;
  color: #ff432e;
}

button {
  background: inherit;
  color: inherit;
  border: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border: 1px solid transparent;
}

@charset "UTF-8";

* {
  box-sizing: border-box;
}

.timeline {
  width: "inherit";
  padding: 100px 50px;
  position: relative;
}

/* for the vertical timeline  */
.timeline:before {
  content: "";
  position: absolute;
  top: 50px;
  left: calc(50%);
  bottom: 110px;
  width: 6px;
  background: #ddd;
}

/* for the timeline ends */
.timeline-marker:before {
  left: calc(50vw - 7px);
  background: transparent;
  content: "";
  width: 20px;
  height: auto;
  border: none;
  border-radius: 0;
  top: 50px;
  bottom: 110px;
  position: absolute;
  border-top: 6px solid #ddd;
  border-bottom: 6px solid #ddd;
}

/* for the first timeline point to not be the end */
.timeline:after {
  content: "";
  display: table;
  clear: both;
}

/* for the text to appear in front of the marker */
.entry {
  clear: both;
  text-align: left;
  position: relative;
}

/* for the left text to be on the left instead of right */
.entry .title {
  margin-bottom: 0.5em;
  float: left;
  width: 50%;
  padding-right: 30px;
  text-align: right;
  position: relative;
}

/* for the timeline circles */
.entry .title:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border: 4px solid #ff432e;
  background-color: #fff;
  border-radius: 100%;
  /* for circle positioning */
  right: -15px;
  top: -1px;
}

/* for the left text to be aligned with the circles */
.entry .title h3 {
  margin: 0;
}

/* .entry .title p {
  margin: 0;
  font-size: 100%;
} */

/* to align the right text header and body */
.entry .body {
  margin: 0 0 3em;
  float: right;
  width: 50%;
  padding-left: 30px;
}

/* line height of the text */
.entry .body p {
  line-height: 1.4em;
}

.entry .body p:first-child {
  margin-top: 0;
  font-weight: 400;
}

/* remove bullet points from the right text body and change colour */
.entry .body ul {
  color: #aaa;
  padding-left: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
  border: 1px solid transparent;
  color: black;
  outline: 0;
}

a,
a:hover,
a:focus,
a:active {
  outline: 0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #ff432e;
}

