a {
  text-decoration: none;
  border: 1px solid transparent;
  color: black;
  outline: 0;
}

a,
a:hover,
a:focus,
a:active {
  outline: 0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #ff432e;
}
